import React from 'react';
import styled from 'styled-components';
import {
  brandColours,
  brandFonts,
  fontSize,
  fontWeights,
  minBreakpointQuery,
} from '../styles';
import { Container } from './ui';
import CompanyCard from './CompanyCard';

const StyledCompanyCards = styled.section`
  margin-bottom: 60px;
  scroll-margin-top: 60px;

  ${minBreakpointQuery.small`
    margin-bottom: 80px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 120px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 150px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-bottom: 170px;
  `}
`;

const StyledOuter = styled.div`
  background-color: ${brandColours.primary};
  top: -60px;
  position: relative;

  ${minBreakpointQuery.xxlarge`
    top: -62px;
  `}
`;

const StyledHeader = styled.header``;

const StyledHeading = styled.h2`
  ${fontSize(18)};
  letter-spacing: 10px;
  margin-left: 36px;
  font-weight: ${fontWeights.regular};
  text-transform: uppercase;
  padding: 20px 0;

  ${minBreakpointQuery.xxlarge`
    margin-left: 60px
  `}
`;

const StyledText = styled.p`
  ${fontSize(26)}
  color: ${brandColours.secondary};
  margin: auto;
  margin: 0 auto 70px;
  font-family: ${brandFonts.secondary};

  ${minBreakpointQuery.small`
    ${fontSize(28)}
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(30)}
  `}

  ${minBreakpointQuery.large`
    ${fontSize(32)}
  `}

  ${minBreakpointQuery.xxlarge`
    ${fontSize(34)}
  `}
`;

const CompanyCards = ({ overline, items, text, contactText }) => (
  <StyledCompanyCards id={'Home'}>
    <StyledHeader>
      <StyledOuter>
        <Container wide={true}>
          <StyledHeading>{overline ? overline : `OUR COMPANIES`}</StyledHeading>
        </Container>
      </StyledOuter>
      <Container>
        <StyledText>{text}</StyledText>
      </Container>
    </StyledHeader>
    <CompanyCard items={items} contactText={contactText} />
  </StyledCompanyCards>
);

export default CompanyCards;
